import { useQuery } from '@tanstack/react-query';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import BackendService from '../services/backendService';
import Metrics from '../services/metrics';
import { parseArray } from '../utils/arrayUtils';
import { TenantLicense } from '../utils/tenantPlanUtils';
import { NewRelic } from './useNewRelic';
import useRouterMatch from './useRouterMatch';
import useTenant, { IndexedTenant } from './useTenant';
function useIdentifyMetrics(uid: string) {
  const { tenantId: activeTenantId } = useRouterMatch();
  const { update: updateIntercom } = useIntercom();
  const router = useRouter();

  const updateContext = useUnleashContext();

  const { data: tenant, tenantPlan } = useTenant() as {
    data: IndexedTenant | undefined;
    tenantPlan: TenantLicense | undefined;
  };
  const cacheKey = ['me', activeTenantId];

  const { data } = useQuery(
    cacheKey,
    () => {
      if (!activeTenantId) return Promise.resolve(undefined);
      return BackendService.instance.users.me(activeTenantId);
    },
    {
      enabled: Boolean(activeTenantId),
      staleTime: 1000 * 60 * 5,
    },
  );

  useEffect(() => {
    if (uid) {
      Metrics.setUid(uid);
      updateIntercom({
        userId: uid,
      });
    }
  }, [uid, updateIntercom]);

  useEffect(() => {
    if (data) {
      Metrics.setEmail(data.email);
      Metrics.setRoleAndAccountType(
        data.role,
        data.accountType,
        data.schoolRole,
        parseArray(data.subjects, true),
        parseArray(data.grades, true),
        data.schoolId,
        data.schoolName,
        data.schoolCity,
        data.schoolState,
        data.schoolZipCode,
        data.schoolDistrictId,
        data.schoolDistrictName,
        data.schoolCountry,
      );
      const metricsReportURL =
        'https://eu.mixpanel.com/project/2182948/view/344387/app/boards#id=8230337';
      const individualUsageReportURL = `https://eu.mixpanel.com/project/2182948/view/344387/app/boards#id=8230337&filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27uid%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27string%7EpropertyType%7E%27string%7EfilterOperator%7E%27equals%7EfilterValue%7E%28%7E%27${data.uid}%29%29%29`;
      const tenantUsageReportURL = tenant?.tenantId
        ? `https://eu.mixpanel.com/project/2182948/view/344387/app/boards#id=8230337&filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27companyId%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27string%7EpropertyType%7E%27string%7EfilterOperator%7E%27equals%7EfilterValue%7E%28%7E%27${tenant?.tenantId}%29%29%29`
        : undefined;
      const emailDomain = data.email.split('@')[1];
      const domainUsageReportURL = `https://eu.mixpanel.com/project/2182948/view/344387/app/boards#id=8230337&filters=%7E%28%7E%28resourceType%7E%27user%7EpropertyName%7E%27%21email%7EpropertyObjectKey%7Enull%7EpropertyDefaultType%7E%27string%7EpropertyType%7E%27string%7EfilterOperator%7E%27contains%7EfilterValue%7E%27${emailDomain}%29%29`;
      updateIntercom({
        email: data.email,
        customAttributes: {
          schoolRole: data.schoolRole,
          grades: data.grades,
          subjects: data.subjects,
          accountType: data.accountType,
          schoolId: data.schoolId,
          schoolName: data.schoolName,
          schoolCity: data.schoolCity,
          schoolState: data.schoolState,
          schoolZipCode: data.schoolZipCode,
          schoolDistrictId: data.schoolDistrictId,
          schoolDistrictName: data.schoolDistrictName,
          schoolCountry: data.schoolCountry,
          usageDashboardIndividual: individualUsageReportURL,
          usageDashboardTenant: tenantUsageReportURL,
          usageDashboardDomain: domainUsageReportURL,
        },
      });
      NewRelic()?.setCustomAttribute('email', data.email);
      NewRelic()?.setCustomAttribute('uid', data.uid);
      NewRelic()?.setUserId(data.uid);
    }
  }, [data, updateIntercom, tenant]);

  useEffect(() => {
    if (data && tenant && tenantPlan) {
      const plan = tenantPlan.coarseLevel;
      const subPlan = tenantPlan.fineLevel;
      updateContext({
        userId: data.email,
        properties: {
          email: data.email,
          accountType: data.accountType || 'unknown',
          role: data.role,
          tenantId: tenant.tenantId,
          tenantName: tenant.tenantName,
          plan: plan,
          subPlan: subPlan,
          districtId: tenant.districtId || 'unknown',
          districtName: tenant.districtName || 'unknown',
          state: tenant.state || 'unknown',
          country: tenant.country || 'unknown',
          organisationId: tenant.organisationId || 'unknown',
        },
      });
    }
  }, [data, tenant, tenantPlan, updateContext]);

  useEffect(() => {
    if (tenant && tenantPlan) {
      const plan = tenantPlan.coarseLevel;
      const subPlan = tenantPlan.fineLevel;
      Metrics.setTenantId(tenant.tenantId);
      Metrics.setCompanyAttributes({
        createdAt: tenant.createdAt || '',
        createdBy: tenant.createdBy || '',
        companyName: tenant.tenantName,
        companyId: tenant.tenantId,
        plan: plan,
        subPlan: subPlan,
        districtId: tenant.districtId || 'unknown',
        districtName: tenant.districtName || undefined,
        state: tenant.state || undefined,
        country: tenant.country || undefined,
        organisationId: tenant.organisationId || 'unknown',
      });
      NewRelic()?.setCustomAttribute('plan', plan);
      NewRelic()?.setCustomAttribute('subPlan', subPlan);
      NewRelic()?.setCustomAttribute('tenantId', tenant.tenantId);
      NewRelic()?.setCustomAttribute('companyId', tenant.tenantId);
      NewRelic()?.setCustomAttribute('districtId', tenant.districtId || null);
      NewRelic()?.setCustomAttribute('companyName', tenant.tenantName);
      updateIntercom({
        company: {
          companyId: tenant.tenantId,
          name: tenant.tenantName,
          plan: plan,
          customAttributes: {
            subPlan: subPlan,
            createdAt: tenant.createdAt ? Math.floor(tenant.createdAt / 1000) : 0, // intercom api wants it in seconds for some reason...
            createdBy: tenant.createdBy,
            districtId: tenant.districtId,
            organisationId: tenant.organisationId,
          },
        },
        customAttributes: {
          plan,
          subPlan,
          districtName: tenant.districtName || undefined,
          state: tenant.state || undefined,
          country: tenant.country || undefined,
        },
      });
    }
  }, [tenant, tenantPlan, updateIntercom]);

  /**
   * Here we track route change so we know we have atleast the id of the user when track
   */

  //TODO: What do youb think, remove these ones?
  //TODO: What do youb think, remove these ones?
  //TODO: What do youb think, remove these ones?
  useEffect(() => {
    // track initial page loads
    Metrics.getLogger().logEvent(
      //TODO: this is very event heavy
      'Route.Change',
      {
        pathname: window.location.pathname,
        url: router.pathname,
        search: window.location.search,
      },
      true,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO: What do youb think, remove these ones?
  //TODO: What do youb think, remove these ones?
  //TODO: What do youb think, remove these ones?
  useEffect(() => {
    // Setup tracking for router.events at Nextjs
    const handleRouteChange = () => {
      Metrics.getLogger().logEvent(
        //TODO: this is very event heavy
        'Route.Change',
        {
          pathname: window.location.pathname,
          url: router.pathname,
          search: window.location.search,
        },
        true,
      );
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);
}

export default useIdentifyMetrics;
