import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import BackendService from '../services/backendService';
import { TenantUser } from '../services/TenantUserService/TenantUserService';
import useRouterMatch from './useRouterMatch';

function useMe() {
  const { tenantId: activeTenantId } = useRouterMatch();
  const cacheKey = ['me', activeTenantId];
  const client = useQueryClient();
  const { data, error, isLoading } = useQuery(
    cacheKey,
    () => {
      if (!activeTenantId) return Promise.resolve(undefined);
      return BackendService.instance.users.me(activeTenantId);
    },
    { staleTime: 1000 * 60 * 60 },
  );

  const {
    mutate: editProfile,
    isLoading: isEditingProfile,
    isSuccess: isEditProfileSuccess,
  } = useMutation(
    (config: { editedProfile: Partial<TenantUser>; onSuccess?: () => void }) => {
      return BackendService.instance.users.editMyProfile(config.editedProfile);
    },
    {
      onSettled: () => {
        client.invalidateQueries(cacheKey);
        // Invalidate the creator cache so you can verify information are up to date
        client.invalidateQueries(['creators', `${data?.uid}_${activeTenantId}`]);
      },
      onMutate: mutateBody => {
        const previousProfile = client.getQueryData<TenantUser>(cacheKey);
        client.setQueryData<TenantUser | undefined>(cacheKey, profile => {
          if (!profile || !mutateBody) return profile;
          return {
            ...profile,
            description:
              mutateBody.editedProfile.description === undefined ||
              mutateBody.editedProfile.description === null
                ? profile.description
                : mutateBody.editedProfile.description,
            username:
              mutateBody.editedProfile.username === undefined ||
              mutateBody.editedProfile.username === null
                ? profile.username
                : mutateBody.editedProfile.username,
            bio:
              mutateBody.editedProfile.bio === undefined ||
              mutateBody.editedProfile.bio === null
                ? profile.bio
                : mutateBody.editedProfile.bio,
            schoolCountry:
              mutateBody.editedProfile.schoolCountry === undefined ||
              mutateBody.editedProfile.schoolCountry === null
                ? profile.schoolCountry
                : mutateBody.editedProfile.schoolCountry,
            schoolId:
              mutateBody.editedProfile.schoolId === undefined ||
              mutateBody.editedProfile.schoolId === null
                ? profile.schoolId
                : mutateBody.editedProfile.schoolId,
            accountType:
              mutateBody.editedProfile.accountType === undefined ||
              mutateBody.editedProfile.accountType === null
                ? profile.accountType
                : mutateBody.editedProfile.accountType,
            templateDefaultSharingPublic:
              mutateBody.editedProfile.templateDefaultSharingPublic === undefined ||
              mutateBody.editedProfile.templateDefaultSharingPublic === null
                ? profile.templateDefaultSharingPublic
                : mutateBody.editedProfile.templateDefaultSharingPublic,
            useNewDesigner:
              mutateBody.editedProfile.useNewDesigner === undefined ||
              mutateBody.editedProfile.useNewDesigner === null
                ? profile.useNewDesigner
                : mutateBody.editedProfile.useNewDesigner,
            schoolRole:
              mutateBody.editedProfile.schoolRole === undefined ||
              mutateBody.editedProfile.schoolRole === null
                ? profile.schoolRole
                : mutateBody.editedProfile.schoolRole,
            subjects:
              mutateBody.editedProfile.subjects === undefined ||
              mutateBody.editedProfile.subjects === null
                ? profile.subjects
                : mutateBody.editedProfile.subjects,
            grades:
              mutateBody.editedProfile.grades === undefined ||
              mutateBody.editedProfile.grades === null
                ? profile.grades
                : mutateBody.editedProfile.grades,
          };
        });
        // Return the snapshotted value
        return () => client.setQueryData(cacheKey, previousProfile);
      },
      onSuccess: (data, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );

  //@deprecated - use editProfile instead
  const { mutate: setNickName, isSuccess: setNicknameSuccess } = useMutation(
    (newNickName: string) => {
      if (!data) return Promise.resolve({ nickName: '' });
      return BackendService.instance.users.setNickName(newNickName);
    },
    {
      onSettled: () => {
        client.invalidateQueries(cacheKey);
      },

      onMutate: nickName => {
        if (!nickName) return;
        client.cancelQueries(cacheKey);
        const previous = client.getQueryData<TenantUser | undefined>(cacheKey);
        if (!previous) {
          // eslint-disable-next-line no-console
          console.error('Mutating before possible mutation');
        }
        client.setQueryData<TenantUser | undefined>(cacheKey, existingUser => {
          if (!existingUser) return existingUser;
          return {
            ...existingUser,
            username: nickName,
          };
        });
        // Return the snapshotted value
        return () => client.setQueryData(cacheKey, previous);
      },
    },
  );

  const schoolState = useMemo(() => data?.schoolState || undefined, [data]);

  return {
    me: data,
    isBusiness: data && data.accountType === 'professional',
    setNickName,
    editProfile,
    setNicknameSuccess,
    isEditProfileSuccess,
    firstLetter: data?.username?.substring(0, 1) || '-',
    error,
    isLoading,
    isEditingProfile,
    schoolState,
  };
}

export default useMe;
