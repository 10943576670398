import mixpanel, { Dict } from 'mixpanel-browser';

import { getBaseUrl, isDevelopment, isProduction } from '../../utils/client';
import { ById } from '../backendService/types';

// Docs: https://developer.mixpanel.com/docs/javascript

const getVersion = () => process.env.REACT_APP_VERSION || '-1';

let globalProperties: Dict = {
  version: getVersion(),
};

export default class Metrics {
  static instance: Metrics;

  distinctId?: string;

  constructor() {
    if (Metrics?.instance) {
      return Metrics.instance;
    }
    Metrics.instance = this;
    return this;
  }

  static getLogger() {
    if (!mixpanel) throw new Error('Not initialized properly');
    return {
      logEvent: (
        event: string,
        properties?: Dict,
        trackWhileUnauthenticated?: boolean,
      ) => {
        if (Metrics?.instance?.distinctId || trackWhileUnauthenticated) {
          return mixpanel.track(`APP.${event}`, { ...globalProperties, ...properties });
        }
      },
      logGameEvent: (
        event: string,
        properties?: Dict,
        isPreview?: boolean | null,
        isDemoGame?: boolean | null,
        trackWhileUnauthenticated?: boolean,
      ) => {
        if (Metrics?.instance?.distinctId || trackWhileUnauthenticated) {
          return mixpanel.track(
            `APP.${isDemoGame ? 'Demo.' : ''}${isPreview ? 'Preview.' : ''}${event}`,
            {
              ...globalProperties,
              ...properties,
            },
          );
        }
      },
    };
  }

  static withMetricsIdentifier(url: string) {
    if (typeof window !== 'undefined') {
      const postfix = url.endsWith('?')
        ? `SMTID=${this.getDistinctId()}`
        : `?SMTID=${this.getDistinctId()}`;
      return `${url}${postfix}`;
    } else {
      return url;
    }
  }

  static getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  static init(token: string) {
    globalProperties = {
      version: getVersion(),
    };
    mixpanel.init(token, {
      api_host: `${getBaseUrl()}/api/stats`,
      ip: true,
      debug: !isProduction(),
      persistence: 'localStorage',
      ignore_dnt: isDevelopment(),
    });
  }

  static setTenantId(tenantId: string) {
    mixpanel.register({
      tenantId: tenantId,
    });
  }

  static setFlag(flagName: string, isEnabled: boolean) {
    if (!new Metrics().distinctId) {
      //Prevent setting flags when user is not authenticated.
      //Unblock using useFlag on anonymous pages
      return;
    }

    mixpanel.register({
      [flagName]: isEnabled,
    });
  }

  static setCompanyAttributes(attributes: ById<string | number | undefined>) {
    mixpanel.register(attributes);
    mixpanel.people.set(attributes);
  }

  static setEmail(email?: string) {
    if (email) {
      mixpanel.people.set({
        $email: email,
      });
    }
  }

  static setIdp(idp?: string) {
    if (idp) {
      mixpanel.people.set({
        idp,
      });
    }
  }

  static setRoleAndAccountType(
    role?: string,
    accountType?: string,
    schoolRole?: string,
    subjects?: string[],
    grades?: string[],

    schoolId?: number,
    schoolName?: string,
    schoolCity?: string | null,
    schoolState?: string | null,
    schoolZipCode?: string | null,
    schoolDistrictId?: number | null,
    schoolDistrictName?: string | null,
    schoolCountry?: string | null,
  ) {
    if (role) {
      mixpanel.people.set({
        role: role,
        accountType,
        schoolRole,
        subjects,
        grades,
        schoolId,
        schoolName,
        schoolCity,
        schoolState,
        schoolZipCode,
        schoolDistrictId,
        schoolDistrictName,
        schoolCountry,
      });
      mixpanel.register({
        role,
        accountType,
        schoolRole,
        subjects,
        grades,
        schoolId,
        schoolName,
        schoolCity,
        schoolState,
        schoolZipCode,
        schoolDistrictId,
        schoolDistrictName,
        schoolCountry,
      });
      this.getLogger().logEvent('appIdentifyRole');
    }
  }

  static setUid(uid?: string) {
    if (uid) {
      new Metrics().distinctId = uid;
      globalProperties.uid = uid;
      mixpanel.people.set({
        uid,
      });
      mixpanel.register({
        uid,
      });
      mixpanel.identify(uid);
    }
  }

  static incrementGamePlayed() {
    mixpanel.people.increment('Real.Games.Played');
  }
  static incrementTestGamePlayed() {
    mixpanel.people.increment('Test.Games.Played');
  }
  static incrementLessonsCreated() {
    mixpanel.people.increment('Lessons.Created');
  }
  static incrementLessonsDownloaded() {
    mixpanel.people.increment('Discover.Lessons.Downloaded');
  }
  static incrementGeneratorPlayed() {
    mixpanel.people.increment('Generator.Played');
  }
  static incrementDiscoveredLessonsPlayed() {
    mixpanel.people.increment('Discover.Lessons.Played');
  }
}
